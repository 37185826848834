import axios from 'axios';

class EventPing {
    start(url) {
        this.url = url;
        setInterval(this.intervalHandler.bind(this), 10000);
    }

    intervalHandler() {
        axios.get(this.url).then(function () {
            console.log("Ping sent")
        })
    }
}

export default EventPing;
